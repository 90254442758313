export default class {
  static specs(card) {
    return [this.spec(card)]
  }

  static spec(card) {
    let openIcon, openOnClick

    // if ($tpu.globals.isStandaloneView) {
    //   openIcon = $tpu.keys.icnOpenInNew()
    //   openOnClick = {
    //     "action": "windows/openWeb",
    //     "url": card.url
    //   }
    // } else {
    //   openIcon = $tpu.keys.icnOpenInCurrent()
    //   openOnClick = {
    //     "action": "commands/custom",
    //     "name": "cards/open",
    //     "properties": {
    //       "cardId": card.id
    //     }
    //   }
    // }

    openIcon = $tpu.keys.icnOpenInNew()
    openOnClick = {
      "action": "windows/openWeb",
      "url": card.url
    }

    return {
      "view": "panels/vertical",
      "width": "matchParent",
      "childViews": [
        {
          "view": "h4",
          "text": "Actions"
        },
        {
          "view": "spacer",
          "height": 8
        },
        {
          "view": "button",
          "icon": { "material": { name: openIcon } },
          "styleClasses": ["tonal", "small"],
          "text": "Open card",
          "width": "matchParent",
          "onClick": openOnClick
        },
        {
          "view": "spacer",
          "height": 18
        },
        {
          "view": "hr",
          "width": "matchParent"
        },
        {
          "view": "spacer",
          "height": 18
        },
        {
          "view": "button",
          "icon": { "material": { name: $tpu.keys.icnDestroy() } },
          "styleClasses": ["tonal", "small", "error"],
          "text": "Delete",
          "width": "matchParent",
          "onClick": {
            "action": "sheets/select",
            "message": "This action cannot be undone. Are you sure?",
            "buttons": [
              {
                "text": "Yes, delete permanently",
                "onClick": {
                  "action": "commands/custom",
                  "name": "cards/destroy",
                  "properties": {
                    // "onlyRefreshBoard": true,
                    "cardId": card.id,
                    "scopeCardId": card.shapeUp.scopeCardId,
                    "projectId": card.shapeUp.projectId
                  },
                  // "onSuccess": {
                  //   "action": "dialogs/close"
                  // },
                },
                "type": "button"
              }
            ]
          }
        }
      ]
    }
  }
}
